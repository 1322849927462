import i18n from "@/i18n";
// import { markRaw, toRaw } from "vue";
// import {
//   Plus,
//   Delete,
//   BottomLeft,
//   TopRight,
//   Search,
//   Download,
//   Edit,
// } from "@element-plus/icons-vue";
// import api from "@/api";
import moment from "moment";
import { defineEmits } from "vue";
// import { getUploadUrl } from "../../utils/tools";
const { t } = i18n.global;

const emit = defineEmits(["AddCpyEvent"]);

export default {
  formCols: [
    {
      type: "ipt",
      label: t("cars.vPlate"),
      field: "vPlate",
      placeholder: t("cars.vPlate"),
      val: "",
      required: true,
    },
    {
      type: "sel",
      label: t("cars.vVehType"),
      field: "vVehType",
      placeholder: t("cars.vVehType"),
      val: "2",
      data: [],
      required: true,
    },
    {
      type: "sel_btn",
      label: t("cars.vCompanyID"),
      field: "vCompanyID",
      placeholder: t("cars.vCompanyID"),
      val: "",
      required: true,
      click: (evt, it, fn) => {
        fn({ val: evt, type: "add_cpy" });
      },
    },
    // {
    //   type: "ipt",
    //   label: t("cars.vBobyColor"),
    //   field: "vBobyColor",
    //   placeholder: t("cars.vBobyColor"),
    //   val: "",
    //   required: false,
    // },
    {
      type: "ipt",
      label: t("cars.sNo"),
      field: "sNo",
      placeholder: t("cars.sNo"),
      val: "",
      required: true,
    },
    // {
    //   type: "ipt",
    //   label: t("cars.sMonthFlow"),
    //   field: "sMonthFlow",
    //   placeholder: t("cars.sMonthFlow"),
    //   val: "3072",
    //   required: true,
    // },
    // {
    //   type: "ipt",
    //   label: t("cars.sMonthDay"),
    //   field: "sMonthDay",
    //   placeholder: t("cars.sMonthDay"),
    //   val: "1",
    //   required: true,
    // },
    // {
    //   type: "ipt",
    //   label: t("cars.sResidualFlow"),
    //   field: "sResidualFlow",
    //   placeholder: t("cars.sResidualFlow"),
    //   val: "0",
    //   required: true,
    // },
    {
      type: "sel",
      label: t("cars.dType"),
      field: "dType",
      placeholder: t("cars.dType"),
      val: "1",
      required: true,
      data: [],
    },
    {
      type: "ipt",
      label: t("cars.dName"),
      field: "dName",
      placeholder: t("cars.dName"),
      val: "",
      required: true,
    },
    {
      type: "sel",
      label: t("cars.dChannelNum"),
      field: "dChannelNum",
      placeholder: t("cars.dChannelNum"),
      val: "4",
      required: false,
      data: [],
      change: (evt, it, fn) => {
        fn({ val: evt, toField: "dPara", toType: "ipt_ipts" });
      },
    },
    {
      type: "",
    },

    {
      type: "ipt_ipts",
      label: t("cars.dPara"),
      field: "dPara",
      placeholder: t("cars.dPara"),
      val: "CH1,CH2,CH3,CH4",
      required: false,
    },
    {
      type: "",
    },

    // {
    //   type: "ipt",
    //   label: t("cars.vxlh"),
    //   field: "vxlh",
    //   placeholder: t("cars.vxlh"),
    //   val: "",
    //   required: false,
    // },
    // {
    //   type: "ipt",
    //   label: t("cars.vxh"),
    //   field: "vxh",
    //   placeholder: t("cars.vxh"),
    //   val: "",
    //   required: false,
    // },
    // {
    //   type: "ipt",
    //   label: t("cars.vInstaller"),
    //   field: "vInstaller",
    //   placeholder: t("cars.vInstaller"),
    //   val: "",
    //   required: false,
    // },
    // {
    //   type: "date",
    //   label: t("cars.vStartServDate"),
    //   field: "vStartServDate",
    //   placeholder: t("cars.vStartServDate"),
    //   val: moment().format("YYYY-MM-DD"),
    //   required: false,
    // },
    // {
    //   type: "date",
    //   label: t("cars.vStopServDate"),
    //   field: "vStopServDate",
    //   placeholder: t("cars.vStopServDate"),
    //   val: moment().add(50, "years").format("YYYY-MM-DD"),
    //   required: false,
    // },
    {
      type: "radio",
      label: t("cars.vState"),
      field: "vState",
      placeholder: t("cars.vState"),
      val: "1",
      required: false,
      data: [
        {
          value: "1",
          name: t("map.zhengchang"),
        },
        {
          value: "0",
          name: t("map.guanbi"),
        },
      ],
    },
    {
      type: "",
    },
    {
      type: "radio_img",
      label: t("cars.vIcoID"),
      field: "vIcoID",
      placeholder: t("cars.vIcoID"),
      val: "1",
      required: false,
      data: [
        {
          value: "1",
          path: "images/01_1.png",
        },
        {
          value: "2",
          path: "images/02_1.png",
        },
        {
          value: "3",
          path: "images/03_1.png",
        },
        {
          value: "4",
          path: "images/04_1.png",
        },
        {
          value: "5",
          path: "images/05_1.png",
        },
        {
          value: "6",
          path: "images/06_1.png",
        },
        {
          value: "7",
          path: "images/07_1.png",
        },
      ],
    },
  ],
};
